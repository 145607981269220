import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>DeterLab is now SPHERE and our front page has moved.</p>
        <a
          className="App-link"
          href="https://launch.sphere-testbed.net"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://launch.sphere-testbed.net
        </a>
      </header>
    </div>
  );
}

export default App;
